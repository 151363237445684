import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { USERTYPE_MOVER } from '../../../util/types';
import { NamedLink } from '../../../components';

import css from './NoSearchResultsMaybe.module.css';

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll, currentUser } = props;
  const { userType } = (currentUser && currentUser?.id && currentUser?.attributes?.profile?.publicData) || {};
  
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;
  return hasNoResult ? (
    <div className={css.noSearchResults}>
      <FormattedMessage id="SearchPage.noResults" />
      <br />
      {hasSearchParams ? (
        <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
          <FormattedMessage id={'SearchPage.resetAllFilters'} />
        </button>
      ) : null}
      <p>
        {/* {
          userType == USERTYPE_MOVER ? 
          <NamedLink className={css.createListingLink} name="NewListingPage">
            <FormattedMessage id="SearchPage.createListing" />
          </NamedLink> : null
        } */}
        {/* <NamedLink className={css.createListingLink} name="NewListingPage">
            <FormattedMessage id="SearchPage.createListing" />
          </NamedLink> */}
      </p>
    </div>
  ) : null;
};

export default NoSearchResultsMaybe;
